import React, {useEffect, useState} from 'react';
import {Flex, Heading} from "@chakra-ui/layout";
import {Box, Button, Divider, FormControl, FormLabel, Grid, GridItem, Image, SimpleGrid, Text} from "@chakra-ui/react";
import * as Yup from "yup";
import {settingCampaignBudget} from "../../store/adCampaign";
import {useDispatch, useSelector} from "react-redux";
import moment from "moment";
import axios from "../../helpers/axios";
import {Input} from "@chakra-ui/input";
import _ from 'lodash'
import {Form, Formik} from "formik";
import ErrorModal from "../../components/PopupModal/ErrorModal";


const initialValue = {
    startDate: "",
    endDate: "",
    totalBudget: "",
    duration: ""
}
const validationSchema = Yup.object({
    startDate: Yup.date().required().label("Start Date").max(Yup.ref('endDate'), "Start date can't be after End date"),
    endDate: Yup.date().min(
        Yup.ref('startDate'),
        "End date can't be before Start date").label("End Date"),
    totalBudget: Yup.string().required().label("Total Budget"),
});

function CampaignBudget({nextStep, prevStep, handleNotes}) {

    const [channels, setChannels] = useState([])
    const [isErrorModalOpen, setIsErrorModalOpen] = useState(false)
    const [errorSubmit, setErrorSubmit] = useState("");
    const dispatch = useDispatch()
    const {campaignBudget, objective, businessDetails} = useSelector((state) => state.adCampaign)
    const [budgetDetails, setBudgetDetails] = useState(initialValue)
    const [selectedChannels, setSelectedChannels] = useState([])
    const [isError, setIsError] = useState(false)

    useEffect(() => {
        if (budgetDetails.startDate && budgetDetails.endDate) {
            const from = moment(budgetDetails.startDate);
            const to = moment(budgetDetails.endDate);
            const diff = to.diff(from, 'days');
            diff <= 0  ? setIsError(true) : setIsError(false)

            setBudgetDetails({...budgetDetails, duration: diff > 0 ? diff + 1 : 0})


            const payload = {
                objective,
                budget: budgetDetails.totalBudget / (diff + 1),
                industry: businessDetails.industry
            }
            if (budgetDetails.totalBudget && objective && businessDetails.industry && diff > 0) {
                axios.post("/spreadsheet-config/demographics/channels", payload).then((res) => {
                    setChannels(res.data)
                })
            }else{
                setChannels([])
            }
        }
    }, [budgetDetails.startDate, budgetDetails.endDate, budgetDetails.duration, budgetDetails.totalBudget])

    useEffect(() => {
        if (!_.isEmpty(campaignBudget)) {
            setBudgetDetails(campaignBudget)
            setSelectedChannels(campaignBudget.selectedChannels)
        }
    }, [campaignBudget])

    const activeTab = {
        border: "2px solid #1F95FF",
        boxSizing: "border-box"
    }

    function handleClick(value) {
        const channelNames = selectedChannels.map((e) => e.name)
        if (channelNames.includes(value.name)) {
            const filterChannels = selectedChannels.filter((e) => e.name !== value.name)
            setSelectedChannels(filterChannels)
        } else {
            setSelectedChannels([...selectedChannels, value])
        }
    }

    function handleCloseErrorModal() {
        setIsErrorModalOpen(false)
        setErrorSubmit("")
    }

    useEffect(() => {
        AddMoreBudget()
    },[selectedChannels])

    function AddMoreBudget(){
        if(!!selectedChannels && budgetDetails.duration){
            const recommendedChannels = selectedChannels.map((e) => parseInt(e?.minimumDaily?.replace("$","")) * budgetDetails.duration)
            const appliedBudget = recommendedChannels.reduce((previousValue, currentValue) => {
                return previousValue + currentValue
            },0)
            if(budgetDetails.totalBudget < appliedBudget){
                const updatedChannels = selectedChannels.filter((e, index) => index < selectedChannels.length - 1)
                setSelectedChannels(updatedChannels)
                setIsErrorModalOpen(true)
                setErrorSubmit(`Please Add $${appliedBudget - budgetDetails.totalBudget} more to select this channel`)
            }
        }
    }

    function onSubmit(values) {
        dispatch(settingCampaignBudget({...values, selectedChannels}))
        nextStep()
    }

    return (
        <div>
            <Heading mt={5} fontSize="3xl" fontFamily='poppins'>Campaign Budget</Heading>
            <Formik initialValues={budgetDetails}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                    enableReinitialize>
                {({values, errors, setFieldValue, touched}) => (
                    <Box fontFamily={"poppins"} as={Form} noValidate>
                        <Box minHeight={460}>
                            <Grid templateColumns='repeat(5, 1fr)' gap={6} mt={10}>
                                <GridItem colSpan={2}>
                                    <FormControl isRequired>
                                        <FormLabel>Start Date</FormLabel>
                                        <Input placeholder='Start Date' fontSize={15} type='date' name='startDate'
                                               isInvalid={errors.startDate && touched.startDate}
                                               value={values.startDate}
                                               onChange={(e) => {
                                                   setBudgetDetails({
                                                       ...budgetDetails,
                                                       startDate: e.target.value
                                                   })
                                                   setFieldValue("startDate", e.target.value)
                                               }}/>
                                        {errors.startDate && touched.startDate && (
                                            <Text color="red.500" fontSize="sm" mt={2}>
                                                {errors.startDate}
                                            </Text>
                                        )}
                                        {isError && <Text color="red.500" fontSize="sm" mt={2}>
                                            Start date should be earlier then End date
                                        </Text>}
                                    </FormControl>
                                </GridItem>
                                <GridItem colSpan={2}>
                                    <FormControl isRequired>
                                        <FormLabel>End Date</FormLabel>
                                        <Input placeholder='End Date' name='endDate' type='date'
                                               isInvalid={errors.endDate && touched.endDate} value={values.endDate}
                                               onChange={(e) => {
                                                   setBudgetDetails({
                                                       ...budgetDetails,
                                                       endDate: e.target.value
                                                   })
                                                   setFieldValue("endDate", e.target.value)
                                               }}/>
                                        {errors.endDate && touched.endDate && (
                                            <Text color="red.500" fontSize="sm" mt={2}>
                                                {errors.endDate}
                                            </Text>
                                        )}
                                        {isError && <Text color="red.500" fontSize="sm" mt={2}>
                                            End date should be greater then Start date
                                        </Text>}
                                    </FormControl>
                                </GridItem>
                                <GridItem>
                                    <FormControl>
                                        <FormLabel>Duration</FormLabel>
                                        <Input placeholder='Duration' name='duration' isReadOnly value={values.duration}/>
                                    </FormControl>
                                </GridItem>
                            </Grid>
                            <Grid templateColumns='repeat(2, 1fr)' gap={6} mt={6}>
                                <GridItem>
                                    <FormControl isRequired>
                                        <Flex gap={1}>
                                            <Image
                                                mt={1}
                                                src={`${process.env.PUBLIC_URL}/images/budget.svg`}
                                                height="18px"
                                            />
                                            <FormLabel>Total Budget</FormLabel>
                                        </Flex>
                                        <Input placeholder='Total Budget'
                                               isInvalid={errors.totalBudget && touched.totalBudget} name='totalBudget'
                                               type='text' id='totalBudget' value={values.totalBudget}
                                               onChange={(e) => {
                                                   setBudgetDetails({
                                                       ...budgetDetails,
                                                       totalBudget: e.target.value
                                                   });
                                                   setSelectedChannels([]);
                                                   setFieldValue("totalBudget", e.target.value)
                                               }}/>
                                        {errors.totalBudget && touched.totalBudget && (
                                            <Text color="red.500" fontSize="sm" mt={2}>
                                                {errors.totalBudget}
                                            </Text>
                                        )}
                                    </FormControl>
                                </GridItem>
                            </Grid>
                            {!!channels.length && <>
                                <Text fontSize='lg' fontWeight={700} mt={8} mb={2}>Auto Recommended Channels</Text>
                                <SimpleGrid columns={[1, 1, 1, 4]} spacing='20px'>
                                    {
                                        channels.map((item, index) => {
                                            const channel = selectedChannels.map((e) => e.name)
                                            const channels = ["amazon","display","instagram","tiktok","facebook","google","waze","youtube","discovery","pinterest","quora","reddit","twitch","twitter","wechat","weibo","snapchat","linkedin","microsoft"]
                                            return (
                                                <Box
                                                    cursor={"pointer"}
                                                    border={"2px solid #E7E7E9"}
                                                    borderRadius={"10px"}
                                                    height='70px'
                                                    display={"flex"}
                                                    p={4}
                                                    style={channel.includes(item.name) ? activeTab : null}
                                                    onClick={() => handleClick(item)}
                                                >
                                                    <Box display={"flex"} alignItems={"center"} gap={"14px"}
                                                         color={"#1d1d1d"}>
                                                        <Image
                                                            src={channels.includes(item?.channel?.toLowerCase()) ? `${process.env.PUBLIC_URL}/images/${item.channel.toLowerCase()}.png`: `${process.env.PUBLIC_URL}/images/display.png` }
                                                            height="34px"
                                                        />
                                                        <Flex direction='column' gap={1}>
                                                            <Heading fontSize='md' fontFamily={"poppins"}
                                                                     color={"#1d1d1d"}
                                                                     fontWeight={500}>{item.name}</Heading>
                                                        </Flex>
                                                    </Box>
                                                </Box>
                                            )
                                        })
                                    }
                                </SimpleGrid></>}
                        </Box>
                        <Divider my={8}/>
                        <div style={{display: 'flex', justifyContent: 'space-between'}}>
                            <Button fontWeight={400} fontFamily={"poppins"} leftIcon={<Image
                                src={`${process.env.PUBLIC_URL}/images/notes.svg`}
                                height="18px"
                            />} onClick={handleNotes}>Add Notes</Button>
                            <div style={{display: "flex", justifyContent: "flex-end", marginBottom: "5rem"}}>
                                <Button mr={4} _hover={{bg: ''}} color={"#1F95FF"} bgColor={"#FFFFFF"} border={"2px"}
                                        borderColor="#1F95FF" py={5} px={8}
                                        onClick={() => prevStep()}>Back</Button>
                                <Button type='submit'
                                        _hover={{bg: ''}} color={"#FFFFFF"} bgColor={"#1F95FF"} border={"2px"}
                                        borderColor="#1F95FF" py={5} px={8}
                                        isDisabled={!!!selectedChannels.length}>Next</Button>
                            </div>
                        </div>
                    </Box>)}
            </Formik>
            <ErrorModal
                isOpen={isErrorModalOpen}
                onClose={() => handleCloseErrorModal()}
                description={errorSubmit}
            />
        </div>
    );
}

export default CampaignBudget;
